@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@import './../assets/scss/custom.scss';
@import './../assets/scss/menu.scss';
@import './../assets/scss/footer.scss';
@import './../assets/scss/home.scss';
@import './../assets/scss/form.scss';
@import './../assets/scss/dashboard.scss';
@import './../assets/scss/case.scss';

@import '~bootstrap/scss/bootstrap';
