/* The following block can be included in a custom.scss */
$break-small: 320px;
$break-large: 900px;

/* make the customizations */
$primary: #00a6de;
$secondary: #808080;
$theme-colors: (
  'info': tomato,
  'danger': #008080,
  'primary': $primary,
  'secondary': $secondary,
);

body {
  font-family: 'Lato' !important;
}
