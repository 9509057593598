#root {
  .container-fluid {
    padding: 15px 0px;
  }
}

.jumbotron {
  background-color: transparent !important;
}

.wp-block-group {
  margin-bottom: 20px;
  .wp-block-cover,
  .wp-block-cover-image {
    position: relative;
    background-color: #000;
    background-size: cover;
    background-position: 50%;
    min-height: 430px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.8;

    .wp-block-cover__inner-container {
      width: calc(100% - 70px);
      z-index: 1232;
      color: $secondary;
    }
  }
}

.carousel-caption {
  position: static !important;
  background-color: $primary;
  opacity: 0.8;
  @media screen and (min-width: $break-large) {
    position: absolute !important;
  }
}
