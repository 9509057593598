.box {
  margin-top: 5em;
  background: #ffffff;
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.box {
  .box-header {
    p {
      font-weight: 200;
      color: $secondary;
    }
  }
  .box-content {
    .wp-block-image {
      img {
        width: 100%;
      }
    }
    form {
      .actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 0.75rem 0;
        .btn {
          width: 200px;
        }
      }
      .actions > * {
        margin: 0.25rem;
      }
      label {
        font-size: 13px;
      }
      .radios {
        padding-top: 5px;
      }
      .radios > * {
        margin-right: 2rem;
      }
      .form-control.valid {
        border-color: teal;
        color: teal;
      }
      .form-control.invalid {
        border-color: tomato;
        color: tomato;
      }
      .form-group {
        .list-group {
          cursor: pointer;
          max-height: 150px;
          margin-bottom: 10px;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
}
