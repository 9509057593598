.nav-link.active {
  color: $primary !important;
  border-bottom: 3px solid;
  font-weight: 700;
}

.nav-link {
  color: $secondary !important;
  font-weight: 700;
}

.navbar-nav {
  p {
    font-weight: 400;
    color: grey;
    @media screen and (min-width: $break-large) {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 38px;
    }
  }
}
.breadcrumb {
  background-color: transparent !important;
}
