.case {
  .row:first-child {
    margin-bottom: 100px;
  }
  .row {
    margin-bottom: 60px;
    .box {
      .box-header {
        min-height: 120px;
        div:first-child {
          float: left;
          padding: 20px;
        }
        div {
          h2 {
            line-height: 100px;
          }
        }
      }
      .box-content {
        p {
          color: $secondary;
          text-align: justify;
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }
  }
}
