.dashboard {
  .row:first-child {
    margin-bottom: 100px;
  }
  .row {
    margin-bottom: 60px;
    div {
      .card {
        .card-img-overlay {
          top: auto;
          z-index: 11;
          color: #ffffff;
          transition: 0.3s;
          text-rendering: optimizeLegibility;
          .card-title {
            font-weight: 500;
          }
          .card-text {
            font-size: 11px;
          }
        }
        .card-img-overlay:hover {
          bottom: 5px;
          transition: 0.3s;
          text-rendering: optimizeLegibility;
        }
        .card-body {
          .card-text {
            color: $secondary;
            min-height: 100px;
          }
          .card-link {
            color: $secondary;
            font-size: 11px;
            svg {
              margin-right: 3px;
            }
          }
        }
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          z-index: 10;
          background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0,
            rgba(0, 0, 0, 0.36) 28%,
            rgba(0, 0, 0, 0.65) 51%
          );
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0)),
            color-stop(28%, rgba(0, 0, 0, 0.36)),
            color-stop(51%, rgba(0, 0, 0, 0.65))
          );
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0,
            rgba(0, 0, 0, 0.36) 28%,
            rgba(0, 0, 0, 0.65) 51%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#a6000000',GradientType=0);
        }
      }
    }
  }
}
