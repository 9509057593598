.footer {
  display: block;
  @media screen and (min-width: $break-small) {
    position: relative;
    margin-top: 100px;
  }
  bottom: 0;
  width: 100%;
  color: white;
  height: 275px;
  background-image: url('./../img/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .layer {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    display: table;
    h2 {
      font-size: 25px;
    }
    p {
      color: $secondary;
    }
  }
  .info {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0px;
    background: $secondary;
    display: table;
    p {
      display: table-cell;
      vertical-align: middle;
    }
  }
}
